import Image from 'next/image';
import React from 'react';
import Title from '../utils/Title';

const About: React.FC = () => (
  <div className='container mx-auto my-4 md:my-8'>
    <Title>O nas</Title>
    <div className='px-4 text-center mx-auto'>
      <div className='col-span-2 order-last md:order-first md:col-span-1 text-gray-500 mx-auto text-center text-sm font-light'>
        <p className='mb-4'>
          Każdy człowiek nosi w sobie duszę bohatera, która potrzebuje jedynie
          iskry, aby się ujawnić. Marka Gym Hero wyzwala tę iskrę, jednocząc i
          wspierając w osiąganiu wielkich rzeczy.
        </p>
        <p className='mb-4'>
          Nasza praca jest pełna zaangażowania w kobiecy sport. Sednem naszej
          działalności jest trening siłowy, ponieważ wierzymy, że każdy sukces
          zaczyna się od ciężkiej pracy w klubowym zaciszu.
        </p>
        <p className='mb-4'>
          Siła, gibkość i wewnętrzna dyscyplina, to klucz do pierwszego miejsca
          na podium i to jest właśnie DNA marki Gym Hero.
        </p>
        <p className='mb-4 hidden md:block'>
          W Gym Hero jesteśmy skupieni na rozwijaniu technologii seamless, która
          najlepiej współgra z każdym rodzajem sportowego wysiłku. Nasza odzież
          oddycha, szybko schnie, jest wytrzymała i gotowa by spełnić
          najbardziej wymagające oczekiwania.
        </p>
        <p className='mb-4 hidden md:block'>
          Tym, co nas wyróżnia jest podejście do aktywnego życia. Nasze
          kapsułowe kolekcje łączące modę sportową i codzienną, pozwalają Ci
          poczuć wsparcie również w drodze na trening.
        </p>
        {/* <p className='opacity-50'>
          <Image width='32' height='32' src='/gh.svg' />
        </p> */}
      </div>
      {/* <div className='col-span-2 md:col-span-1 text-center'>
        <Image src='/static/211206/o-nas.jpg' width={752} height={439} />
      </div> */}
    </div>
  </div>
);

export default About;
