import { GetStaticProps, NextPage } from "next";
import { useRouter } from "next/dist/client/router";
import Head from "next/head";
import Image from "next/image";
import { useState } from "react";
import Hero from "../components/hero/Hero";
import About from "../components/homepage/About";
import Categories from "../components/homepage/Categories";
import Newsletter from "../components/newsletter";
import Loading from "../components/utils/Loading";
import Modal from "../components/utils/Modal";
import { getPages, PageWithLink, useConfirm } from "@wk93/socommerce-sdk";
import Layout from "../components/layout";
import Collections from "../components/homepage/Collections";
import TimeContent from "../components/TimeContent";
import {
  Banner,
  BannerElement,
  getBanner,
  getBanners,
} from "../utils/getBanners";
import { isWithinInterval } from "date-fns";
import axios from "axios";
import Link from "next/link";
import Title from "../components/utils/Title";

interface Props {
  pages: PageWithLink[];
  banners: Banner[];
}

const HomePage: NextPage<Props> = ({ pages, banners }) => {
  const router = useRouter();
  const [email, setEmail] = useState("");
  const [modal, setModal] = useState({
    type: "",
    title: "",
    description: "",
    show: false,
  });

  const { isLoading } = useConfirm(async (action, email) => {
    if (action === "SUCCESS_SUBSCRIBE" && email) {
      setEmail(email);
      await axios.post("https://gymhero.eu/api/send/club", { email });
    }

    if (action === "ERROR") {
      setModal({
        type: "error",
        show: true,
        title: "Wystąpił błąd",
        description:
          "Najprawdopodobniej już potwierdzieś operację lub link jest niepoprawny.",
      });
    } else {
      setModal({
        type: "success",
        show: true,
        title: "Dziękujemy za potwierdzenie",
        description: action === "SUCCESS_SUBSCRIBE"
          ? "Zostałeś zapisany do naszego newslettera"
          : action === "SUCCESS_REGISTER"
          ? "Twoje konto zostało aktywowane"
          : action === "SUCCESS_NOTIFY"
          ? "Powiadomimy Cię, kiedy produkt znowu pojwi się w naszej ofercie"
          : "Twój adres email został usunięty z naszej bazy newsletterowej",
      });
    }

    router.replace("/", undefined, { shallow: true });
  });

  const mainBannerIndex = banners.map((b) => b.id).indexOf(
    "63b1633f38987cdce3b94070",
  );

  const firstSmallBannerIndex = banners.map((b) => b.id).indexOf(
    "653b822e78c754a485bf156d",
  );

  const secondSmallBannerIndex = banners.map((b) => b.id).indexOf(
    "653b838278c754a485bf198f",
  );

  const secondBannerIndex = banners.map((b) => b.id).indexOf(
    "643d44b8da6ad3c1b68156e9",
  );

  const mainBanner = banners[mainBannerIndex].elements;
  const firstSmallBanners = banners[firstSmallBannerIndex].elements;
  const secondBanner = banners[secondBannerIndex].elements;
  const secondSmallBanners = banners[secondSmallBannerIndex].elements;
  const newsletterBanners =
    banners.filter((b) => b.id === "65451303ecc7c78a2e87fe67").length > 0 &&
      banners.filter((b) => b.id === "65451303ecc7c78a2e87fe67")[0].elements
          .length > 0 &&
      banners.filter((b) => b.id === "65451303ecc7c78a2e87fe67")[0].elements[0]
          .images.length > 0
      ? banners.filter((b) => b.id === "65451303ecc7c78a2e87fe67")[0]
        .elements[0].images[0].src
      : null;

  const mapAndFilter = (b: Array<BannerElement>) =>
    (b || []).filter((element) => element.lang === "pl")
      .filter((element) => {
        if (element.start && element.end) {
          return isWithinInterval(new Date(), {
            start: new Date(element.start),
            end: new Date(element.end),
          });
        }
        return true;
      }).map((item) => ({
        desktop: {
          src: item.images[0].src,
          width: item.images[0].width,
          height: item.images[0].height,
        },
        mobile: {
          src: item.images[1].src,
          width: item.images[1].width,
          height: item.images[1].height,
        },

        alt: item.alt,
        href: item.href,
      }));

  const mappedBanners = mapAndFilter(mainBanner);
  const mappedSecondBanners = mapAndFilter(secondBanner);

  return (
    <Layout>
      <Head>
        <title>
          Gym Hero - Legginsy damskie, bezszwowe, seamless, damska odzież
          sportowa.
        </title>
        <meta
          name="description"
          content="Gym Hero - polska marka sportowa. Szeroki asortyment ubrań sportowych w technologii seamless w przystępnych cenach, wysoka jakość wykonania."
        >
        </meta>
      </Head>
      <div className="container mx-auto">
        {isLoading ? <Loading absolute /> : null}
        {/* <Popup /> */}
        <Modal
          show={modal.show}
          type={modal.type as any}
          title={modal.title}
          description={modal.description}
        >
          <button
            onClick={() => {
              setModal({ show: false, type: "", title: "", description: "" });
              if (email.length > 1) {
                setEmail("");
              }
            }}
            type="button"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-orange-400 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Zamknij
          </button>
        </Modal>
        {mappedBanners.length > 0
          ? (
            <Hero
              desktopSrc={mappedBanners[0].desktop.src}
              width={mappedBanners[0].desktop.width}
              height={mappedBanners[0].desktop.height}
              mobileSrc={mappedBanners[0].mobile.src}
              href={mappedBanners[0].href}
            />
          )
          : null}

        <div className="grid grid-cols-3 gap-4 md:gap-8 text-center my-4 md:my-8 sm:px-4">
          {secondSmallBanners.map((banner, index) => (
            <Link href={banner.href} key={index}>
              <a className="col-span-3 md:col-span-1 relative hover-trigger cursor-pointer">
                <div className="image-block">
                  <Image
                    alt={banner.alt}
                    src={banner.images[0].src}
                    width={banner.images[0].width}
                    height={banner.images[0].height}
                    quality={100}
                    layout="responsive"
                  />
                </div>
              </a>
            </Link>
          ))}
        </div>

        {mappedSecondBanners.length > 0
          ? (
            mappedSecondBanners.map((b) => (
              <Hero
                key={b.desktop.src}
                desktopSrc={b.desktop.src}
                width={b.desktop.width}
                height={b.desktop.height}
                mobileSrc={b.mobile.src}
                href={b.href}
              />
            ))
          )
          : null}

        <Title>Gym Hero Collections</Title>
        <div className="grid grid-cols-3 gap-4 md:gap-8 text-center my-4 md:my-8 sm:px-4">
          {firstSmallBanners.map((banner, index) => (
            <Link href={banner.href} key={index}>
              <a className="col-span-3 md:col-span-1 relative hover-trigger cursor-pointer">
                <div className="image-block">
                  <Image
                    alt={banner.alt}
                    src={banner.images[0].src}
                    width={banner.images[0].width}
                    height={banner.images[0].height}
                    quality={100}
                    layout="responsive"
                  />
                </div>
              </a>
            </Link>
          ))}
        </div>

        <div className="px-0 sm:px-4">
          <div className="grid grid-cols-1 md:grid-cols-2">
            <div className="relative hidden h-full md:block">
              {newsletterBanners
                ? (
                  <Image
                    src={newsletterBanners}
                    layout="fill"
                    objectFit="cover"
                    quality={100}
                  />
                )
                : null}
            </div>
            <div className="flex items-center justify-center bg-[#d0b99a]">
              <div className="max-w-sm py-12 px-4 text-white">
                <img src="/b/ghnews.svg" className="mb-12 w-full" />
                <Newsletter />
              </div>
            </div>
          </div>
        </div>

        {/* <Banner /> */}

        {
          /* <Title>Poznaj Gym Hero</Title>
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-8 mb-4 md:mb-8 px-4'>
          <Link href='/campaign'>
            <a>
              <div className='relative hover-trigger cursor-pointer'>
                <div className='image-block'>
                  <Image
                    src='/static/210614/kampanie.jpg'
                    width={752}
                    height={500}
                  />
                </div>
                <div className='absolute top-0 bottom-0 left-0 right-0 z-10 hover-target bg-white opacity-50'></div>
                <div className='absolute top-0 bottom-0 left-0 right-0 z-20 hover-target'>
                  <div className='flex h-full w-full items-center justify-center'>
                    <button
                      type='submit'
                      className='w-2/12 h-8 border border-transparent rounded text-white bg-orange-400 hover:bg-orange-500 focus:outline-none text-sm'
                    >
                      Zobacz
                    </button>
                  </div>
                </div>
              </div>
            </a>
          </Link>
          <Link href='/technology'>
            <a>
              <div className='relative hover-trigger cursor-pointer'>
                <div className='image-block'>
                  <Image
                    src='/static/210614/technologie.jpg'
                    width={752}
                    height={500}
                  />
                </div>
                <div className='absolute top-0 bottom-0 left-0 right-0 z-10 hover-target bg-white opacity-50'></div>
                <div className='absolute top-0 bottom-0 left-0 right-0 z-20 hover-target'>
                  <div className='flex h-full w-full items-center justify-center'>
                    <button
                      type='submit'
                      className='w-2/12 h-8 border border-transparent rounded text-white bg-orange-400 hover:bg-orange-500 focus:outline-none text-sm'
                    >
                      Zobacz
                    </button>
                  </div>
                </div>
              </div>
            </a>
          </Link>
        </div> */
        }
      </div>
      {
        /* <Posts
        posts={pages.map((page) => ({
          link: page.link.slug,
          thumbnail: page.media[0],
        }))}
      /> */
      }
      <About />
      {/* <Newsletter /> */}
    </Layout>
  );
};

export const getStaticProps: GetStaticProps = async ({ params }) => {
  const tagBlogId = "6107bfb53dc2850015eb17a8";
  const banners = await getBanners();

  const { pages } = await getPages(
    `${process.env.NEXT_PUBLIC_API_URL}/public`,
    { lang: "pl", tagId: tagBlogId },
  );

  return {
    props: { pages: pages.splice(0, 2), banners: banners },
    revalidate: 900,
  };
};

export default HomePage;
