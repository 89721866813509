import React from 'react';

const Title: React.FC = ({ children }) => (
  <div className='mb-4 md:mb-8 text-center uppercase text-lg text-gray-600 flex tracking-widest justify-center'>
    <div className='border-b border-black border-opacity-5 px-8 pb-1 font-bebas text-xl'>
      {children}
    </div>
  </div>
);
export default Title;
