import Image from "next/image";
import Link from "next/link";
import React from "react";
import { classNames } from "../../utils";

interface Props {
  desktopSrc?: string;
  mobileSrc?: string;
  height?: number;
  width?: number;
  href?: string;
  mobileHeight?: number;
  withoutMargin?: boolean;
}

const Hero: React.FC<Props> = ({
  desktopSrc,
  mobileSrc,
  width = 1248,
  height = 579,
  mobileHeight = 767,
  withoutMargin = false,
  href,
}) => {
  const imgBlock = (
    <div className={classNames(!withoutMargin ? "mb-4 md:mb-8" : "" , "px-0 sm:px-4 text-[0px]")}>
      <span className="image-block block md:hidden">
        <Image
          src={mobileSrc || "/220609/sld-mobile.jpg"}
          alt="Modal"
          width={767}
          height={mobileHeight}
          quality={100}
        />
      </span>
      <div className="image-block hidden md:block">
        <Image
          src={desktopSrc || "/220609/sld.jpg"}
          alt="Modal"
          width={width}
          height={height}
          quality={100}
        />
      </div>
    </div>
  );

  return href ? (
    <Link href={href}>
      <a>{imgBlock}</a>
    </Link>
  ) : (
    imgBlock
  );
};

export default Hero;
